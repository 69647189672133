<template>
  <section>
    <div class="bg"></div>
    <h1>A B O U T &nbsp; M E</h1>
  </section>
  <OpeningScreen title="Victor Warno" />
  <SlotMachine />
  <Introduction />
  <Timeline />
  <Bubbles />
  <Contacts />
</template>

<script>
import OpeningScreen from './components/OpeningScreen.vue'
import SlotMachine from './components/SlotMachine.vue'
import Introduction from './components/Introduction.vue'
import Timeline from './components/Timeline.vue'
import Bubbles from './components/Bubbles.vue'
import Contacts from './components/Contacts.vue'

import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
  name: 'App',
  components: {
    OpeningScreen,
    SlotMachine,
    Introduction,
    Timeline,
    Bubbles,
    Contacts,
  },
  data() {
    return {
      scrollHandler: null
    }
  },
  created() {
    // Debounced scroll handler for better performance
    this.scrollHandler = this.debounce(() => {
      if (window.scrollY + window.innerHeight + 5 > document.documentElement.scrollHeight) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }, 100)
    
    window.addEventListener('scroll', this.scrollHandler)
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollHandler)
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger)

    // Optimize parallax effect by using a single timeline
    const sections = gsap.utils.toArray("section")
    const timeline = gsap.timeline()

    sections.forEach((section, i) => {
      section.bg = section.querySelector(".bg")
      
      if (i) {
        section.bg.style.backgroundPosition = `50% ${-innerHeight / 2}px`
        
        timeline.to(section.bg, {
          backgroundPosition: `50% ${innerHeight / 2}px`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            scrub: true
          }
        })
      } else {
        section.bg.style.backgroundPosition = "50% 0px"
        
        timeline.to(section.bg, {
          backgroundPosition: `50% ${innerHeight / 2}px`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: "top top",
            end: "bottom top",
            scrub: true
          }
        })
      }
    })
  },
  methods: {
    debounce(func, wait) {
      let timeout
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout)
          func(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
      }
    }
  }
}

// This starter template is using Vue 3 experimental <script setup> SFCs
// Check out https://github.com/vuejs/rfcs/blob/script-setup-2/active-rfcs/0000-script-setup.md
</script>

<style>
@font-face {
  font-family: FiraCode;
  src: url('./assets/FiraCode-VF.ttf') format('truetype');
  font-display: swap;
}

#app {
  font-family: FiraCode, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: darkslategrey;
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 400;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/joel-filipe-Wc8k-KryEPM-unsplash.jpg");
  will-change: transform;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
</style>