<template>
  <section class="opening-panel">
    <div class="scroll-item">Software Developer</div>
    <div class="scroll-item">Mathematician</div>
    <div class="scroll-item">Taylor Swift Fan</div>
    <div class="top-box" style="position: fixed; bottom: 53%;"></div>
    <div class="top-box" style="position: fixed; top: 53%;"></div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  mounted: () => { 
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline()
    tl.to(".scroll-item", {yPercent: -615, duration: 2})

    ScrollTrigger.create({
      animation: tl,
      trigger: ".opening-panel",
      start: "top top",
      end: "+=2250",
      scrub: true,
      pin: true,
    })
  }
}
</script>

<style scoped>
section {
  background-color:darkslategrey;
  height: 100vh;
  text-align: center;
  display: flex;
	flex-direction: column;
	justify-content: center;
  font-size: xx-large;
  color: white;
  font-family: monospace;
}

h1 {
  font-family: monospace
}

.top-box {
  height: 50vh;
  background-color: darkslategrey;
  z-index: 100;
  margin: 20px;
}

.scroll-item {
  font-family: monospace;
  font-size: x-larger;
  margin-top: 60px;
  position: relative;
  top: 60px;
}

@media (max-width: 1000px) {
  section {
    width: 96vw;
  }

  .top-box {
    width: 96vw;
  }
  
  h3 {
    font-size: 16px;
  }
}

@media (min-width: 1001px) {
  section {
    width: 99vw;
  }

  .top-box {
    width: 99vw;
  }
}
</style>