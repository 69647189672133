<template>
  <section class="bubble-panel">
    <h1 style="margin-bottom: -100px">Skills</h1>
    <div class="category programming-languages">
      <h2 style="margin-left: -90%">Programming</h2>
    </div>
    <div class="category languages">
      <h2>Languages</h2>
    </div>
    <div style="position: fixed; left: 0%">
      <div class="bar" style="background: rgb(0, 0, 0); margin-bottom: 39px;"></div>
      <div class="bar" style="background: rgb(255, 0, 0)"></div>
      <div class="bar" style="background: rgb(255, 204, 0); margin-bottom: -39px;"></div>
    </div>
    <div style="position: fixed; left: 25%">
      <div class="red-stripe" style="margin-bottom: 69px;"></div>
      <div class="white-stripe" style="margin-bottom: 60px;"></div>
      <div class="red-stripe" style="margin-bottom: 51px;"></div>
      <div class="white-stripe" style="margin-bottom: 42px;"></div>
      <div class="red-stripe" style="margin-bottom: 33px;"></div>
      <div class="white-stripe" style="margin-bottom: 24px;"></div>
      <div class="red-stripe" style="margin-bottom: 15px;"></div>
      <div class="white-stripe" style="margin-bottom: 6px;"></div>
      <div class="red-stripe" style="margin-bottom: -3px;"></div>
      <div class="white-stripe" style="margin-bottom: -12px;"></div>
      <div class="red-stripe" style="margin-bottom: -21px;"></div>
      <div class="white-stripe" style="margin-bottom: -30px;"></div>
      <div class="red-stripe" style="margin-bottom: -39px;"></div>
      <div class="union" style="margin-bottom: 24px;"></div>
    </div>
    <div style="position: fixed; left: 50%">
      <div class="column" style="background: rgb(0, 85, 164); margin-bottom: -39px;"></div>
      <div class="column" style="background: rgb(255, 255, 255); margin-left: 8.3vw; margin-bottom: -39px;"></div>
      <div class="column" style="background: rgb(239, 65, 53); margin-left: 16.6vw; margin-bottom: -39px;"></div>
    </div>
    <div style="position: fixed; left: 75%">
      <div class="bar2" style="background: rgb(255, 0, 0); height: 58.5px; margin-bottom: 19.5px;"></div>
      <div class="bar2" style="background: rgb(255, 255, 255); margin-bottom: -39px; height: 58.5px;"></div>
    </div>
    <div class="hide-flag-right" style="position: fixed; margin-bottom: -39px;"></div>
    <div class="hide-flag-left" style="position: fixed; margin-bottom: -39px;"></div>
  
    <div class="circle" style="position: relative; left: -100px; bottom: -100px">
      <div class="content">Kotlin</div>
    </div>
    <div class="circle" style="position: relative; right: -100px;">
      <div class="content">JavaScript</div>
    </div>
    <div class="circle" style="position: relative;">
      <div class="content">Python</div>
    </div>
    <div class="circle" style="position: relative; top: -300px;">
      <div class="content">Java</div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  mounted: () => { 
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline()
    tl.to('h1', { opacity: 0 })
      .from('.languages', { opacity: 0 })
      .to('.hide-flag-right', { x: '100vw', width: 0, duration: 6 })
      .to('.hide-flag-left', { width: '99vw', duration: 6 })
      .to('.languages', { xPercent: 70 })
      .from('.programming-languages', { opacity: 0 })
      .from('.circle', { width: 0, height: 0, borderRadius: 0, opacity: 0, duration: 6})
      .to('.circle', { width: 100, height: 100, borderRadius: 50, duration: 6})
      .to('.circle', { width: 0, height: 0, borderRadius: 0, duration: 6})
      

    ScrollTrigger.create({
      animation: tl,
      trigger: ".bubble-panel",
      start: "top top",
      end: "+=2750",
      scrub: true,
      pin: true,
    })
  }
}
</script>

<style scoped>
@font-face {
    font-family: FiraCode;
    src: url('../assets/FiraCode-VF.ttf');
}

section {
  background-color:rgb(185, 130, 47);
  height: 100vh;
  text-align: center;
  display: flex;
	flex-direction: column;
	justify-content: center;
  font-size: xx-large;
  color: white;
}

h1 {
  font-family: monospace
}

@media (max-width: 800px) {
  h2 {
    font-family: monospace;
    font-size: 100%;
  }
}

@media (min-width: 801px) {
  h2 {
    font-family: monospace;
  }
}

.circle {
  width: 110px;
  height: 110px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 55px;
  background: white;
}

.content {
  font-size: medium;
  color: rgb(185, 130, 47); 
  margin-top: 43%;
}

.category {
  position: fixed;
  bottom: 100px;
}

.bar {
  width: 25vw;
  height: 39px;
  position: fixed;
  bottom: 50%;
}

.bar2 {
  height: 39px;
  position: fixed;
  bottom: 50%;
}

.column {
  width: 8.3vw;
  height: 117px;
  position: fixed;
  bottom: 50%;
}

.red-stripe {
  width: 25vw;
  height: 9px;
  position: fixed;
  bottom: 50%;
  background-color: #b22234;
}

.white-stripe {
  width: 25vw;
  height: 9px;
  position: fixed;
  bottom: 50%;
  background-color: white;
}

.union {
  width: 10vw;
  height: 54px;
  position: fixed;
  bottom: 50%;
  background-color: #3c3b6e;
}

.hide-flag-right {
  width: 100vw;
  height: 120px;
  bottom: 50%;
  background-color: rgb(185, 130, 47); 
}

.hide-flag-left {
  width: 0vw;
  height: 120px;
  bottom: 50%;
  left: 0%;
  background-color: rgb(185, 130, 47); 
}

@media (max-width: 1000px) {
  section {
    width: 96vw;
  }

  .hide-flag-right {
    width: 96vw;
  }

  .bar2 {
    width: 24vw;
  }
}

@media (min-width: 1001px) {
  section {
    width: 99vw;
  }

  .hide-flag-right {
    width: 99vw;
  }

  .bar2 {
    width: 24.7vw;
  }
}
</style>