<template>
  <section class="monospace contacts-panel">
    <h2>Contact Me</h2>
    <h3><a href="https://www.linkedin.com/in/victorwarno/">LinkedIn</a> // <a href="https://twitter.com/schmowser">Twitter</a> // <a href="https://github.com/Schmowser">Github</a> // <a href="https://dblp.org/pid/226/2602.html">Publications</a> // <a href="https://dev.to/schmowser">Developer Blog</a></h3>
    <span>Background Photo by <a href="https://unsplash.com/@joelfilip?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Joel Filipe</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
    <div class="loading-bar">Back to Top</div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  mounted: () => { 
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline()
    tl.from('.loading-bar', { xPercent: -100, duration: 2 })

    ScrollTrigger.create({
      animation: tl,
      trigger: ".contacts-panel",
      start: "top top",
      end: "+=1000",
      scrub: true,
      pin: true,
    })
  }
}
</script>

<style scoped>
section {
  background-color:darkslategrey;
  height: 100vh;
  text-align: center;
  display: flex;
	flex-direction: column;
	justify-content: center;
  font-size: xx-large;
  color: white;
}

h3 {
  font-family: monospace;
}

span {
  position: fixed;
  bottom: 10%;
  font-size: large;
}

#monospace {
  font-family: monospace;
}

.loading-bar {
  width: 100vw;
  height: 50px;
  position: fixed;
  bottom: 0%;
  background-color: white; 
  color: darkslategrey;
  font-size: larger;
}

@media (max-width: 1000px) {
  section {
    width: 96vw;
  }
  
  h3 {
    font-size: 16px;
  }
}

@media (min-width: 1001px) {
  section {
    width: 99vw;
  }
}
</style>