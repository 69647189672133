<template>
  <section class="timeline-panel">
    <div class="grid-container">
      <div class="item1">
        <span>
          <h3>
            2011-2018 | 
            HU Berlin | 
            MSc Mathematics
          </h3>
        </span>
      </div>
      <div class="item2">
        <span>
          <h3>
            2016-2018 | 
            Fraunhofer HHI | 
            Researcher
          </h3>
        </span>
      </div>
      <div class="item3">
        <div class="line1"></div>
        <div class="dot1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
        <div class="dot2"></div>
        <div class="line4"></div>
      </div>  
      <div class="item4">
        <div class="line5"></div>
        <div class="dot3"></div>
        <div class="line6"></div>
        <div class="line7"></div>
        <div class="dot4"></div>
        <div class="line8"></div>
      </div>
      <div class="item5">
        <span>
          <h3>
            2018-2022 | 
            Senacor Technologies | 
            (Senior) Developer
          </h3>
        </span>
      </div>
      <div class="item6">
        <span>
          <h3>
            2022-now | 
            Fronius Deutschland | 
            Android Developer
          </h3>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  mounted: () => { 
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline()
    tl.from(".line1", {xPercent: -75, opacity: 0, duration: 1})
      .from([".dot1", ".item1"], {opacity: 0, duration: 0.5})
      .from(".line2", {xPercent: -75, opacity: 0, duration: 1})
      .from(".line3", {xPercent: -75, opacity: 0, duration: 1})
      .from([".dot2", ".item2"], {opacity: 0, duration: 0.5})
      .from(".line4", {xPercent: -75, opacity: 0, duration: 1})
      .from(".line5", {xPercent: -75, opacity: 0, duration: 1})
      .from([".dot3", ".item5"], {opacity: 0, duration: 0.5})
      .from(".line6", {xPercent: -75, opacity: 0, duration: 1})
      .from(".line7", {xPercent: -75, opacity: 0, duration: 1})
      .from([".dot4", ".item6"], {opacity: 0, duration: 0.5})
      .from(".line8", {xPercent: -75, opacity: 0, duration: 1})

    ScrollTrigger.create({
      animation: tl,
      trigger: ".timeline-panel",
      start: "top top",
      end: "+=1000",
      scrub: true,
      pin: true,
    })
  }
}
</script>

<style scoped>
@font-face {
    font-family: FiraCode;
    src: url('../assets/FiraCode-VF.ttf');
}

section {
  background-color:rgb(54, 47, 79);
  height: 100vh;
  text-align: center;
  display: flex;
	flex-direction: column;
	justify-content: center;
  font-size: xx-large;
  color: white;
}

h1 {
  font-family: monospace
}

.grid-container {
  display: grid;
  grid-template-areas:
    'header-left header-right'
    'main-above main-above'
    'main-below main-below'
    'footer-left footer-right';
  gap: 25px;
  padding: 10px;
    width: 100vw;
  height: 100vh;

}

.grid-container > div {
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

.item1 { 
  grid-area: header-left; 
  position: relative;
}
.item1 span { 
  position: absolute;
  bottom: 0;
  left: 5%;
}
.item2 { 
  grid-area: header-right; 
  position: relative;
}
.item2 span { 
  position: absolute;
  bottom: 0;
  right: 5%;
}

.item3 { grid-area: main-above; }
.item4 { grid-area: main-below; position: relative; }
.item4 > div { 
  grid-area: main-below; 
  position: absolute; 
  bottom: 5%; 
}


.item5 { 
  grid-area: footer-left; 
  position: relative;
}
.item5 span { 
  position: absolute;
  top: 0;
  left: 5%;
}
.item6 { 
  grid-area: footer-right; 
  position: relative;
}
.item6 span { 
  position: absolute;
  top: 0;
  right: 5%;
}

.line1 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 0%;
  z-index: 100;
  line-height: 100px;
}

.line2 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 26%;
  line-height: 100px;
}

.line3 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 51%;
  line-height: 100px;
}

.line4 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 76%;
  line-height: 100px;
}

.line5 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 0%;
  z-index: 100;
  line-height: 100px;
}

.line6 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 26%;
  line-height: 100px;
}

.line7 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 51%;
  line-height: 100px;
}

.line8 {
  width: 26%;
  height: 10px;
  background-color: white;
  position: fixed;
  left: 76%;
  line-height: 100px;
}

.dot1 {
  height: 35px;
  width: 35px;
  position: fixed;
  left: 25%;
  margin-top: -13px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

.dot2 {
  height: 35px;
  width: 35px;
  position: fixed;
  left: 75%;
  margin-top: -13px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

.dot3 {
  height: 35px;
  width: 35px;
  position: fixed;
  left: 25%;
  margin-bottom: -13px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

.dot4 {
  height: 35px;
  width: 35px;
  position: fixed;
  left: 75%;
  margin-bottom: -13px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

@media (max-width: 1000px) {
  section {
    width: 96vw;
  }
  
  h3 {
    font-size: 16px;
  }
}

@media (min-width: 1001px) {
  section {
    width: 99vw;
  }
}
</style>