<template>
  <section class="introduction-panel">
    <div class="left-column"><img alt="Vue logo" src="../assets/warno_squared_2025.png" /></div>
    <div class="right-column">I am Victor! And I am fascinated by everything web and animation. That's why I build this website (with Vue) where you can explore everything by scrolling. (Some navigation still requires clicking!)</div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  mounted: () => { 
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline()
    tl.from(".left-column", {xPercent: -250, duration: 1})

    ScrollTrigger.create({
      animation: tl,
      trigger: ".introduction-panel",
      start: "top top",
      end: "+=500",
      scrub: true,
      pin: true,
    })
  }
}
</script>

<style scoped>
@font-face {
    font-family: FiraCode;
    src: url('../assets/FiraCode-VF.ttf');
}

section {
  background-color:rgb(62, 119, 141);
  height: 100vh;
  text-align: center;
  display: flex;
	flex-direction: column;
	justify-content: center;
  font-size: x-large;
  color: white;
}

@media (min-width: 801px) {
  .left-column {
    position: fixed;
    left: 3%;
    top: 10%;
    margin-right: 150px;
  }

  .right-column {
    width: 50%;
    text-align: justify;
    position: fixed;
    right: 5%;
  }

  img {
    height: 80vh;
  }
}

@media (max-width: 800px) {
  .left-column {
    position: fixed;
    top: 5%;
  }

  .right-column {
    width: 80%;
    text-align: justify;
    position: fixed;
    bottom: 10%;
    vertical-align: center;
  }

  img {
    width: 50vw;
  }
}

@media (max-width: 1000px) {
  section {
    width: 96vw;
  }
  
  h3 {
    font-size: 16px;
  }
}

@media (min-width: 1001px) {
  section {
    width: 99vw;
  }
}


#introduction-panel {
  font-family: FiraCode, monospace;
}
</style>